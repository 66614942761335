<template>
  <div>
    <el-form :model="dictData" ref="dictData" label-width="150px" class="demo-ruleForm">
        <!-- 应用名称 -->
      <el-form-item :label="$t('public.ApplicationName')+'：'" prop="appName">
        {{ dictData.appName }}
      </el-form-item>
      <!-- 大小 -->
      <el-form-item :label="$t('public.Size')+'：'" prop="appSizeStr">
        {{ dictData.appSizeStr }}
      </el-form-item>
      <!-- 版本 -->
      <el-form-item :label="$t('public.Version')+'：'" prop="versionName">
        {{ dictData.versionName }}
      </el-form-item>
      <!-- URL -->
      <el-form-item label="URL：" prop="urlschemes">
        {{ dictData.urlschemes }}
      </el-form-item>
      <!-- 应用简介 -->
      <el-form-item :label="$t('AppManage.H5Scan.ApplicationIntroduction')+'：'" prop="memo">
        {{ dictData.memo }}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    dictData: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {};
  },

  methods: {
    handleClose() {
      this.$emit("Close");
    },
  },

  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0;
}
.demo-ruleForm {
  margin-left: 90px;
}
.diailog-footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>