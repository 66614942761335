<template>
  <div style="margin-left: 350px; margin-top: 10px">
    <el-row style="marginleft: 350px; margintop: 20px" :gutter="16">
      <el-col :span="24">
        <el-card shadow="never" style="width: 1000px">
          <el-row :gutter="16">
            <el-col :span="8">
              <span
                >{{ $t("public.ApplicationName") + "："
                }}{{ dataArray.appName }}</span
              >
            </el-col>
            <el-col :span="8">
              <span
                >{{ $t("public.ApplicationID") + "："
                }}{{ dataArray.packageName }}</span
              >
            </el-col>
            <el-col :span="8">
              <span
                >{{ $t("public.Version") + "："
                }}{{ dataArray.versionName }}</span
              >
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="24" style="margin-top: 20px">
        <el-card shadow="never" style="width: 1000px">
          <span v-if="scanScore >= 0">{{
            $t("AppManage.H5Scan.H5appShoptest.Detectedsuccess")
          }}</span>
          <span v-if="scanScore == -2">{{
            $t("AppManage.H5Scan.H5appShoptest.Notdetected")
          }}</span>
          <span v-if="scanScore == -1">{{
            $t("AppManage.H5Scan.H5appShoptest.Detecting")
          }}</span>
          <span v-if="scanScore >= 0" style="margin-left: 620px">
            <el-button
              @click="jiance"
              size="small"
              type="primary"
              style="width: 100px"
              >{{ $t("AppManage.H5Scan.H5appShoptest.Retest") }}</el-button
            >
          </span>
          <span v-if="scanScore == -2" style="margin-left: 600px">
            <el-button
              @click="jiance"
              size="small"
              type="primary"
              style="width: 100px"
              >{{
                $t("AppManage.H5Scan.H5appShoptest.Startdetection")
              }}</el-button
            >
          </span>
          <span v-if="scanScore == -1" style="margin-left: 532px">
            <el-button
              @click="jiance"
              :disabled="true"
              size="small"
              type="primary"
              style="width: 100px"
              >{{ $t("AppManage.H5Scan.H5appShoptest.Retest") }}</el-button
            >
          </span>
        </el-card>
      </el-col>
    </el-row>
    <div v-if="scanScore >= 0">
      <!-- PDF/DOC 报告 -->
      <el-row style="marginleft: 350px" :gutter="16">
        <el-col style="margin-top: 60px; width: 200px">
          <div id="Ding" style="width: 150px; height: 150px"></div>
        </el-col>
        <el-col style="width: 220px">
          <div
            id="main"
            style="width: 425px; height: 300px; margin-left: 25px"
          ></div>
        </el-col>
        <el-col style="width: 200px">
          <p>
            <el-button
              size="small"
              type="primary"
              style="width: 100px; margin-top: 60px; margin-left: 370px"
              @click="report2pdf"
              >{{ $t("AppManage.H5Scan.H5appShoptest.PDFreport") }}</el-button
            >
          </p>
          <p>
            <el-button
              size="small"
              type="primary"
              style="width: 100px; margin-top: 20px; margin-left: 370px"
              @click="report2doc"
              >{{ $t("AppManage.H5Scan.H5appShoptest.DOCreport") }}</el-button
            >
          </p>
        </el-col>
      </el-row>
      <!-- 检测结果 -->
      <el-row style="marginleft: 350px" :gutter="16">
        <el-col :span="24">
          <div
            v-if="this.picChart.high > 0"
            style="width: 1000px; height: 80px; line-height: 80px"
          >
            <b
              >{{ $t("AppManage.H5Scan.H5appShoptest.Detectionresult")
              }}<span style="color: #e84646">{{
                $t("AppManage.H5Scan.H5appShoptest.Highriskstate")
              }}</span
              >，{{ $t("AppManage.H5Scan.H5appShoptest.Codetection")
              }}{{
                this.picChart.high + this.picChart.low + this.picChart.medium
              }}{{ $t("AppManage.H5Scan.H5appShoptest.Abug") }}</b
            >
          </div>
          <div
            v-else-if="this.picChart.medium > 0"
            style="width: 1000px; height: 80px; line-height: 80px"
          >
            <b
              >{{ $t("AppManage.H5Scan.H5appShoptest.Detectionresult")
              }}<span style="color: #f59331">{{
                $t("AppManage.H5Scan.H5appShoptest.Mediumcritical")
              }}</span
              >，{{ $t("AppManage.H5Scan.H5appShoptest.Codetection")
              }}{{
                this.picChart.high + this.picChart.low + this.picChart.medium
              }}{{ $t("AppManage.H5Scan.H5appShoptest.Abug") }}</b
            >
          </div>
          <div
            v-else-if="this.picChart.low > 0"
            style="width: 1000px; height: 80px; line-height: 80px"
          >
            <b
              >{{ $t("AppManage.H5Scan.H5appShoptest.Detectionresult")
              }}<span style="color: #62b2d7">{{
                $t("AppManage.H5Scan.H5appShoptest.Lowcritical")
              }}</span
              >，{{ $t("AppManage.H5Scan.H5appShoptest.Codetection")
              }}{{
                this.picChart.high + this.picChart.low + this.picChart.medium
              }}{{ $t("AppManage.H5Scan.H5appShoptest.Abug") }}</b
            >
          </div>
          <div v-else style="width: 1000px; height: 80px; line-height: 80px">
            <b
              >{{ $t("AppManage.H5Scan.H5appShoptest.Detectionresult")
              }}<span style="color: #008000">{{
                $t("AppManage.H5Scan.H5appShoptest.Safestate")
              }}</span
              >，{{ $t("AppManage.H5Scan.H5appShoptest.Codetection")
              }}{{
                this.picChart.high + this.picChart.low + this.picChart.medium
              }}{{ $t("AppManage.H5Scan.H5appShoptest.Abug") }}</b
            >
          </div>
        </el-col>
      </el-row>
      <el-row style="marginleft: 350px" :gutter="16">
        <el-col :span="24">
          <!-- 基本信息 -->
          <el-card class="box-card" style="width: 1000px">
            <div
              slot="header"
              class="clearfix"
              style="height: 10px; color: #1e89e5"
            >
              <span>
                <b>{{ $t("DeviceManage.Device.Basicinformation") }}</b>
              </span>
            </div>
            <div class="card">
              <div class="text item" style="height: 40px">
                <span
                  >{{ $t("public.ApplicationName") }}：{{
                    dataArray.appName
                  }}</span
                >
              </div>
              <div class="text item" style="height: 40px">
                <span>{{ $t("public.Size") }}：{{ dataArray.appSizeStr }}</span>
              </div>
              <div class="text item" style="height: 40px">
                <span
                  >{{ $t("public.Version") }}：{{ dataArray.versionName }}</span
                >
              </div>
            </div>
            <div
              class="card"
              style="position: absolute; top: 67px; right: 150px"
            >
              <div class="text item" style="height: 40px">
                <span
                  >{{ $t("AppManage.H5Scan.H5appShoptest.CreationTime") }}：{{
                    queryObj.created_date | dateformat
                  }}</span
                >
              </div>
              <div class="text item" style="height: 40px">
                <span
                  >{{ $t("public.ApplicationID") }}：{{
                    dataArray.packageName
                  }}</span
                >
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- URL地址 -->
      <el-row
        style="marginleft: 350px; margintop: 30px; margin-top: 20px"
        :gutter="16"
      >
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.URLaddress") }}</b>
              </span>
            </div>
            <div class="item" style="max-height: 500px; overflow: auto">
              <template>
                <template v-if="zyFile.length > 0 && zyFile[0] !== ''">
                  <p
                    style="
                      height: 23px;
                      border-bottom: 1px solid #ebeef5;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    :title="item"
                    v-for="(item, index) in zyFile"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </template>
                <template v-else>
                  {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
                </template>
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- IP地址 -->
      <el-row
        style="marginleft: 350px; margintop: 30px; margin-top: 20px"
        :gutter="16"
      >
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.IPaddress") }}</b>
              </span>
            </div>
            <div class="item">
              <template>
                <template v-if="ipAdress.length > 0 && ipAdress[0] !== ''">
                  <div style="max-height: 400px; overflow: auto">
                    <p
                      style="height: 23px; border-bottom: 1px solid #ebeef5"
                      v-for="(item, index) in ipAdress"
                      :key="index"
                    >
                      {{ item }}
                    </p>
                  </div>
                </template>
                <template v-if="ipAdress == '' || ipAdress[0] == ''">
                  {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
                </template>
                <!-- <template v-else> {{$t('AppManage.H5Scan.H5appShoptest.Noinfor')}} </template> -->
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- 敏感行为检测 -->
      <el-row
        style="marginleft: 350px; margintop: 30px; margin-top: 20px"
        :gutter="16"
      >
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Sensitivebe") }}</b>
              </span>
            </div>
            <div class="item">
              <div>
                <span style="color: #6f7982">
                  <b
                    >{{ $t("AppManage.H5Scan.H5appShoptest.Description") }}：</b
                  >
                </span>
                {{ $t("AppManage.H5Scan.H5appShoptest.Appall") }}
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- 敏感行为 h5_sensitive_list 赋值 -->
      <el-row style="marginleft: 350px; margintop: -25px" :gutter="16">
        <el-col>
          <div style="max-height: 346px; overflow: auto; width: 1000px">
            <el-table
              :cell-style="{ padding: '3px 0px' }"
              :header-row-style="{ height: '30px' }"
              :data="h5_sensitive_list"
              style="width: 1000px"
            >
              <el-table-column
                prop="name"
                :label="$t('AppManage.H5Scan.H5appShoptest.Name')"
                width="300"
              >
              </el-table-column>
              <el-table-column
                prop="level"
                :label="$t('AppManage.H5Scan.H5appShoptest.Dangerclass')"
                width="250"
              >
                <template v-slot="{ row }">
                  <template v-if="row.level == '0'"
                    ><span style="color: #e84646">
                      {{ $t("AppManage.H5Scan.H5appShoptest.Danger") }}</span
                    >
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('AppManage.H5Scan.H5appShoptest.Detailedinfor')"
              >
                <template v-slot="{ row }">
                  <span
                    style="color: #d38a08; cursor: pointer"
                    @click="behavior(row)"
                    type="primary"
                    >{{
                      $t("AppManage.H5Scan.H5appShoptest.Detailedinfor")
                    }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <!-- 静态检测漏洞 -->
      <el-row
        style="marginleft: 350px; margintop: 30px; margin-top: 20px"
        :gutter="16"
      >
        <el-col>
          <el-card class="box-card" style="width: 1000px">
            <div class="item">
              <div>
                <span style="color: #1e89e5">
                  <b>{{
                    $t("AppManage.H5Scan.H5appShoptest.Detectingvulner")
                  }}</b>
                </span>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- h5_static_vuls 赋值 -->
      <el-row style="marginleft: 350px; margintop: -25px" :gutter="16">
        <el-col>
          <div style="max-height: 346px; overflow: auto; width: 1000px">
            <el-table
              :cell-style="{ padding: '3px 0px' }"
              :header-row-style="{ height: '30px' }"
              :data="h5_static_vuls"
              style="width: 1000px"
            >
              <el-table-column
                prop="name"
                :label="$t('AppManage.H5Scan.H5appShoptest.VulnerabilityName')"
                width="300"
              >
                <template v-slot="{ row }">
                  <template
                    v-if="
                      row.level !== $t('AppManage.H5Scan.H5appShoptest.Secure')
                    "
                  >
                    {{ row.name }}
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                prop="level"
                :label="$t('AppManage.H5Scan.H5appShoptest.VulnerabilityLevel')"
                width="250"
              >
                <template v-slot="{ row }">
                  <!-- <template v-if="row.level == '安全'">
                    <span style="color: green"> 安全</span></template> -->
                  <template
                    v-if="
                      row.level == $t('AppManage.H5Scan.H5appShoptest.Mediumvu')
                    "
                  >
                    <span style="color: #ff8d00">{{
                      $t("AppManage.H5Scan.H5appShoptest.Moderately")
                    }}</span>
                  </template>
                  <template
                    v-if="
                      row.level ==
                      $t('AppManage.H5Scan.H5appShoptest.Lowriskvu')
                    "
                  >
                    <span style="color: #00c7ce">{{
                      $t("AppManage.H5Scan.H5appShoptest.lowrisk")
                    }}</span>
                  </template>
                  <template
                    v-if="
                      row.level ==
                      $t('AppManage.H5Scan.H5appShoptest.Highriskvu')
                    "
                  >
                    <span style="color: #fc2f3d">{{
                      $t("AppManage.H5Scan.H5appShoptest.Highrisk")
                    }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('AppManage.H5Scan.H5appShoptest.Detailedinfor')"
              >
                <template v-slot="{ row }">
                  <span
                    style="color: #d38a08; cursor: pointer"
                    @click="louDong(row)"
                    type="primary"
                    >{{
                      $t("AppManage.H5Scan.H5appShoptest.Detailedinfor")
                    }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
      <div style="margintop: 100px; height: 100px"></div>
    </div>
    <!-- 静态检测漏洞 -->
    <Eldialog
      @handleClose="handleClose"
      :num="'470px'"
      :title="title"
      :dialogVisible="dialogVisible"
      :width="'1000px'"
    >
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{
                  $t("AppManage.H5Scan.H5appShoptest.Vulnerabilityprofile")
                }}</b>
              </span>
            </div>
            <div class="item">
              <div>{{ detailData.summary }}</div>
              <!-- 不能完全展示 -->
              <!-- <div v-html="detailData.summary"></div> -->
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Details") }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.payloadTitle"></div>
              <div
                v-for="(item, index) in detailData.payload"
                :key="index"
                class="line-with-spaces"
              >
                <div>
                  <span>{{ item.lineNumber }}</span
                  ><span
                    :style="{ color: item.color, fontWeight: item.fontWeight }"
                    >{{ item.content }}</span
                  >
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Solution") }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.solution"></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 敏感行为检测 -->
    <Eldialog
      @handleClose="handleClose"
      :width="'1000px'"
      :num="'470px'"
      :title="title"
      :dialogVisible="dialogBehavior"
    >
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{
                  $t("AppManage.H5Scan.H5appShoptest.Behaviorprofile")
                }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.summary"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Details") }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.payloadTitle"></div>
              <div
                v-for="(item, index) in detailData.payload"
                :key="index"
                class="line-with-spaces"
              >
                <div>
                  <span>{{ item.lineNumber }}</span
                  ><span
                    :style="{ color: item.color, fontWeight: item.fontWeight }"
                    >{{ item.content }}</span
                  >
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Solution") }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.solution"></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 敏感内容检测 -->
    <Eldialog
      @handleClose="handleClose"
      :width="'1000px'"
      :num="'470px'"
      :title="title"
      :dialogVisible="dialogBehaviors"
    >
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{
                  $t("AppManage.H5Scan.H5appShoptest.Sensitivecontent")
                }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.summary"></div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Details") }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.payloadTitle"></div>
              <div
                v-for="(item, index) in detailData.payload"
                :key="index"
                class="line-with-spaces"
              >
                <div>
                  <span>{{ item.lineNumber }}</span
                  ><span
                    :style="{ color: item.color, fontWeight: item.fontWeight }"
                    >{{ item.content }}</span
                  >
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Solution") }}</b>
              </span>
            </div>
            <div class="item">
              <div v-html="detailData.solution"></div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 四大组件 Activity-->
    <Eldialog
      @handleClose="handleClose"
      :num="'470px'"
      :title="titlefour"
      :dialogVisible="dialogFour"
    >
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Componentint") }}</b>
              </span>
            </div>
            <div class="item">
              <template
                v-if="fourDataKey.Activity && fourDataKey.Activity.length > 0"
              >
                <p
                  style="margin: 5px 0"
                  v-for="(item, index) in fourDataKey.Activity"
                  :key="index"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
              </template>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{
                  $t("AppManage.H5Scan.H5appShoptest.Callablecomponent")
                }}</b>
              </span>
            </div>
            <div class="item">
              <template v-if="a.length > 0">
                <p
                  style="margin: 5px 0"
                  v-for="(item, index) in a"
                  :key="index"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 四大组件 Service-->
    <Eldialog
      @handleClose="handleClose"
      :num="'470px'"
      :title="titlefour2"
      :dialogVisible="dialogFour2"
    >
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Componentint") }}</b>
              </span>
            </div>
            <div class="item">
              <template
                v-if="fourDataKey.Service && fourDataKey.Service.length > 0"
              >
                <p
                  style="margin: 5px 0"
                  v-for="(item, index) in fourDataKey.Service"
                  :key="index"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
              </template>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{
                  $t("AppManage.H5Scan.H5appShoptest.Callablecomponent")
                }}</b>
              </span>
            </div>
            <div class="item">
              <template v-if="b.length > 0">
                <p
                  style="margin: 5px 0"
                  v-for="(item, index) in b"
                  :key="index"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 四大组件 Provider-->
    <Eldialog
      @handleClose="handleClose"
      :num="'470px'"
      :title="titlefour3"
      :dialogVisible="dialogFour3"
    >
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Componentint") }}</b>
              </span>
            </div>
            <div class="item">
              <template
                v-if="fourDataKey.Provider && fourDataKey.Provider.length > 0"
              >
                <p
                  style="margin: 5px 0"
                  v-for="(item, index) in fourDataKey.Provider"
                  :key="index"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
              </template>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{
                  $t("AppManage.H5Scan.H5appShoptest.Callablecomponent")
                }}</b>
              </span>
            </div>
            <div class="item">
              <template v-if="c.length > 0">
                <p
                  style="margin: 5px 0"
                  v-for="(item, index) in c"
                  :key="index"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 四大组件 Provider-->
    <Eldialog
      @handleClose="handleClose"
      :num="'470px'"
      :title="titlefour4"
      :dialogVisible="dialogFour4"
    >
      <el-row style="width: 100%">
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{ $t("AppManage.H5Scan.H5appShoptest.Componentint") }}</b>
              </span>
            </div>
            <div class="item">
              <template
                v-if="fourDataKey.Receiver && fourDataKey.Receiver.length > 0"
              >
                <p
                  style="margin: 5px 0"
                  v-for="(item, index) in fourDataKey.Receiver"
                  :key="index"
                >
                  {{ item }}
                </p>
              </template>
              <template v-else>
                {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
              </template>
            </div>
          </el-card>
        </el-col>
        <el-col :span="24">
          <el-card class="box-card" style="width: 100%">
            <div slot="header" class="clearfix">
              <span style="color: #1e89e5">
                <b>{{
                  $t("AppManage.H5Scan.H5appShoptest.Callablecomponent")
                }}</b>
              </span>
            </div>
            <div class="item">
              <template>
                <template v-if="d.length > 0">
                  <p
                    style="margin: 5px 0"
                    v-for="(item, index) in d"
                    :key="index"
                  >
                    {{ item }}
                  </p>
                </template>
                <template v-else>
                  {{ $t("AppManage.H5Scan.H5appShoptest.Noinfor") }}
                </template>
              </template>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </Eldialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Eldialog from "../../../../../components/elDialog/index.vue";

export default {
  props: {
    sidd: {
      type: Number,
      default: null,
    },
    scanScore: {
      type: Number,
      default: null,
    },
    dataArray: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Eldialog,
  },
  data() {
    return {
      SiDa: {},
      color: "",
      titleStatus: this.scanScore == 0 ? "检测完成" : "正在检测应用请稍后...",
      titlefour: "Andriod四大组件：Activity",
      titlefour2: "Andriod四大组件：Service",
      titlefour3: "Andriod四大组件：Provider",
      titlefour4: "Andriod四大组件：Receiver",
      title: "获取手机信息",
      mydata: [],
      dictData: {},
      fourData: {}, //四大组件
      fourDataKey: {}, //四大组件的key值
      securityCount: 0, //安全数据出事为0
      a: {},
      b: {},
      c: {},
      d: {},
      signbook: [], //签名证书
      zyFile: [], //资源文件
      ipAdress: [], //IP地址
      email: [], //email 信息
      detailData: {}, //详情的唐匡对象
      // detailSummary: "", //点击静态详情
      //环形分数
      score: [{ value: 0, name: "Engine" }],
      picChart: [],
      queryObj: {
        cert: "[</br>[</br>  Version: V1</br>  Subject: C=US, O=Android, CN=Android Debug</br>  Signature Algorithm: SHA1withRSA, OID = 1.2.840.113549.1.1.5</br></br>  Key:  </br>  Validity: [From: Wed May 03 15:22:34 CST 2017,</br>               To: Fri Apr 26 15:22:34 CST 2047]</br>  Issuer: C=US, O=Android, CN=Android Debug</br>  SerialNumber: [    01]</br></br>]</br>  Algorithm: [SHA1withRSA]</br>  Signature:</br>0000: 22 60 1E CC 07 CA 37 A6   A2 38 23 89 E8 9E F6 7C  &quot;`....7..8#.....</br>0010: 01 AF B9 2D 65 DF 87 FF   7C D3 B3 CD 87 46 51 0C  ...-e........FQ.</br>0020: 27 B7 1A 06 81 75 8E 88   76 3D 99 DA 39 56 1C 65  &#39;....u..v=..9V.e</br>0030: F5 09 7F 91 AD 7D 05 95   E4 F7 93 AB E5 7C FA DC  ................</br>0040: D5 AC CA C9 8B ED 55 73   47 2D E5 C3 56 B5 40 ED  ......UsG-..V.@.</br>0050: EA B0 DD 13 AC 4C C9 8A   05 02 DA 0D D4 A4 CA 8D  .....L..........</br>0060: 90 EC 61 7F A1 28 5A A3   40 1C BE 37 3C C1 D0 A6  ..a..(Z.@..7&lt;...</br>0070: F3 A9 B5 C1 ED E5 F3 21   77 47 BA 95 97 DF F9 87  .......!wG......</br></br>]</br>",
      },
      tableData: [
        {
          category: 1,
          name: "恶意扣费",
        },
        {
          category: 1,
          name: "信息窃取",
        },
        {
          category: 1,
          name: "远程控制",
        },
        {
          category: 1,
          name: "恶意传播",
        },
        {
          category: 1,
          name: "资费消耗",
        },
        {
          category: 1,
          name: "系统破坏",
        },
        {
          category: 1,
          name: "诱骗诈骗",
        },
        {
          category: 1,
          name: "流氓行为",
        },
      ],
      h5_sensitive_list: [],
      h5_static_vuls: [],
      // 权限信息
      permissions: [],
      dialogVisible: false, //静态敏感弹框
      dialogBehavior: false, //敏感行为弹框
      dialogBehaviors: false,
      dialogFour: false, //四大组件
      dialogFour2: false, //四大组件2
      dialogFour3: false, //四大组件3
      dialogFour4: false, //四大组件4
      tabBass: false,
    };
  },
  mounted() {
    this.tableList();
    // this.detailList()
  },
  methods: {
    // 进入页面请求数据
    async tableList() {
      let params = {
        type: "1",
        id: this.sidd + "",
      };
      const res = await this.$axios.post(
        "/httpServe/entAppScan/scanReport",
        params,
        true
      );
      console.log(res, "H5检测结果");
      if (res.data) {
        this.queryObj = res.data; //最外层的数据
        this.tableClean = res.data;
        this.taskId = res.data.report.task_id; //task_id 应用检测详情传的 // 四大组件
        let certsList = res.data.report.cert.split("</br>");
        this.signbook = certsList.splice(0, 10); // 签名证书
        // url地址数据
        if (res.data.report.urls_list.length > 0) {
          var arr = res.data.report.urls_list
            .replace(/[\s+\'\"\[\]]/g, "")
            .split(",");
          var result = [];
          console.log(arr);
          for (const match of arr) {
            result.push(match.replace("u/", ""));
          }
          this.zyFile = result;
          // console.log(this.zyFile);
        }
        // ip地址数据
        if (res.data.report.ips_list.length > 0) {
          this.ipAdress = res.data.report.ips_list
            .replace(/[\s+\'\"\[\]\u]/g, "")
            .split(","); //ip地址
          console.log(this.ipAdress);
        }
        this.permissions = res.data.report.permissions; //权限信息
        console.log(this.permissions);
        if (res.data.report.emails_list.length > 0) {
          this.email = res.data.report.emails_list
            .replace(/[\s+\'\"\[\]\u]/g, "")
            .split(","); //email信息
        }
        // -----------------------------------------------------------------
        let dataStatusSen = res.data.report.h5_sensitive_list; //敏感行为检测 this.h5_sensitive_list
        console.log(dataStatusSen, "dataStatusSen敏感行为");
        for (let index = 0; index < dataStatusSen.length; index++) {
          let contFond = false;
          for (
            let newIndex = 0;
            newIndex < res.data.report.h5_sensitive_vuls.length;
            newIndex++
          ) {
            if (
              dataStatusSen[index].id ==
              res.data.report.h5_sensitive_vuls[newIndex]
            ) {
              this.h5_sensitive_list.push(dataStatusSen[index]);
            }
          }
        }
        console.log(this.h5_sensitive_list, "this.h5_sensitive_list---");
        let dataStaticLevel = res.data.report.h5_static_vuls; //静态检测漏洞
        for (let index = 0; index < dataStaticLevel.length; index++) {
          let contFond = false;
          for (
            let newIndex = 0;
            newIndex < res.data.report.h5_vul_list.length;
            newIndex++
          ) {
            if (
              dataStaticLevel[index].id == res.data.report.h5_vul_list[newIndex]
            ) {
              if (dataStaticLevel[index].level == 1) {
                dataStaticLevel[index].level = this.$t(
                  "AppManage.H5Scan.H5appShoptest.Highriskvu"
                );
              } else if (dataStaticLevel[index].level == 2) {
                dataStaticLevel[index].level = this.$t(
                  "AppManage.H5Scan.H5appShoptest.Mediumvu"
                );
              } else if (dataStaticLevel[index].level == 3) {
                dataStaticLevel[index].level = this.$t(
                  "AppManage.H5Scan.H5appShoptest.Lowriskvu"
                );
              }
              contFond = true;
              break;
            }
          }
          if (!contFond) {
            dataStaticLevel[index].level = this.$t(
              "AppManage.H5Scan.H5appShoptest.Secure"
            );
          }
        }
        dataStaticLevel.forEach((item) => {
          if (
            item.level == this.$t("AppManage.H5Scan.H5appShoptest.Highriskvu")
          ) {
            this.h5_static_vuls.push(item);
          }
        });
        dataStaticLevel.forEach((item, index) => {
          if (
            item.level == this.$t("AppManage.H5Scan.H5appShoptest.Mediumvu")
          ) {
            this.h5_static_vuls.push(item);
          }
        });
        dataStaticLevel.forEach((item, index) => {
          if (
            item.level == this.$t("AppManage.H5Scan.H5appShoptest.Lowriskvu")
          ) {
            this.h5_static_vuls.push(item);
          }
        });
        console.log(this.h5_static_vuls.length, "h5_static_vuls");

        // 局部变量
        var sz1 = res.data.report.h5_sensitive_list; //敏感
        var sb1 = res.data.report.h5_sensitive_vuls; //敏感
        var sz2 = res.data.report.h5_static_vuls; //静态
        var sb2 = res.data.report.h5_vul_list; //静态
        // 找到静态对象id
        let szids2 = sz2.map((item) => item.id);
        let jingtai = szids2.filter((item) => sb2.indexOf(item) == -1).length;
        // console.log(jingtai, "静态");
        // 找到敏感对象的id
        let szids = sz1.map((item) => item.id);
        let mingan = szids.filter((item) => sb1.indexOf(item) == -1).length;
        console.log(mingan, "szids.filter(item=>sb1.indexOf(item)==-1)");
        let sum = 0;
        sum = jingtai + mingan;
        // console.log(sum,'---综合数据')
        // 定义全局变量
        this.securityCount = sum;
        // console.log(this.securityCount,'----安全的总数据')
        // 环形数据
        let score1 = [];
        score1.push({
          value: res.data.report.score,
        });
        this.score = score1;
        this.picChart = res.data.report.risk;
        console.log(this.picChart);
        if (this.picChart.high > 0) {
          this.color = "#E84646";
        } else if (this.picChart.high == 0 && this.picChart.medium > 0) {
          this.color = "#f59331";
        } else if (
          this.picChart.high == 0 &&
          this.picChart.low > 0 &&
          this.picChart.medium == 0
        ) {
          this.color = "#62B2D7";
        } else {
          this.color = "#008000";
        }
        this.Ring(this.color);
        this.pieChart();
        this.fourData = {
          Activity: res.data.report.components.Activity.component.length,
          Provider: res.data.report.components.Provider.component.length,
          Receiver: res.data.report.components.Receiver.component.length,
          Service: res.data.report.components.Service.component.length,
        };
        this.fourDataKey = {
          Activity: res.data.report.components.Activity.component,
          Provider: res.data.report.components.Provider.component,
          Receiver: res.data.report.components.Receiver.component,
          Service: res.data.report.components.Service.component,
        };
        this.SiDa = {
          Activity:
            res.data.report.components.Activity.exported_component.length,
          Provider:
            res.data.report.components.Provider.exported_component.length,
          Receiver:
            res.data.report.components.Receiver.exported_component.length,
          Service: res.data.report.components.Service.exported_component.length,
        };
        this.a = res.data.report.components.Activity.exported_component;
        this.b = res.data.report.components.Service.exported_component;
        this.c = res.data.report.components.Provider.exported_component;
        this.d = res.data.report.components.Receiver.exported_component;
      }
    },
    // 四大组件
    fourCompent() {
      this.dialogFour = true;
    },
    // 四大组件2
    fourCompent2() {
      this.dialogFour2 = true;
    },
    // 四大组件3
    fourCompent3() {
      this.dialogFour3 = true;
    },
    // 四大组件3
    fourCompent4() {
      this.dialogFour4 = true;
    },
    // 敏感行为检测弹框
    behavior(val) {
      this.title =
        val.name + this.$t("AppManage.H5Scan.H5appShoptest.Detailedinfor");
      this.dialogBehavior = true;
      this.detailList(val); //------------*****??????
    },
    behaviors(val) {
      this.title =
        val.name + this.$t("AppManage.H5Scan.H5appShoptest.Detailedinfor");
      this.dialogBehaviors = true;
      this.detailList(val); //------------*****??????
    },
    // 静态敏感弹框
    louDong(val) {
      this.title =
        val.name + this.$t("AppManage.H5Scan.H5appShoptest.Detailedinfor");
      this.dialogVisible = true;
      this.detailList(val);
    },
    // 重新检测
    async jiance() {
      this.titleStatus = this.$t("AppManage.H5Scan.Betesting");
      let params = {
        ids: [this.sidd + ""],
      };
      const res = await this.$axios.post(
        "/httpServe/entAppScan/send",
        params,
        true
      );
      console.log(res);
      if (res.code == 200) {
        // this.tabBass = true
        this.tableList();
        this.$emit("haldesotr");
      }
    },
    // 下载PDF
    async report2pdf() {
      let params = {
        reportType: 1,
        ids: [this.sidd],
      };
      const res = await this.$axios.post(
        "/httpServe/entApp/report2pdf",
        params,
        true
      );
      var param = {
        path: res.data,
      };
      const s = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(s.data);
    },
    // 下载doc
    async report2doc() {
      let params = {
        reportType: 1,
        ids: [this.sidd],
      };
      // console.log(params)
      const res = await this.$axios.post(
        "/httpServe/entApp/report2doc",
        params,
        true
      );
      var param = {
        path: res.data,
      };
      const s = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(s.data);
    },
    // 应用检测
    async detailList(arr) {
      let params = {
        // type: '1',
        id: this.sidd + "",
        vulid: arr ? arr.vulid : "",
        // taskid: this.taskId
      };
      console.log(params);
      const res = await this.$axios.post(
        "/httpServe/entAppScan/detail",
        params,
        true
      );
      this.$nextTick(() => {
        let aa = res.data.payload;
        console.log(res.data.payload, "res.data.payload");
        if (aa.substr(0, 1) == "[") {
          console.log("第一个字符是一个左方括号（[）");
          let str = aa.replace(/[\s+\'\"\[\]]/g, "");
          let arr = str.replace(/\,/g, "</br>");
          this.detailData = {
            payload: arr,
            solution: res.data.solution,
            summary: res.data.summary,
          };
          return;
        }
        if (aa.substr(0, 1) == "{") {
          let data = JSON.parse(aa);
          console.log(data, "第一个字符是一个{");
          let title = ""; // 初始化 title 变量
          let html = ""; // 初始化 html 变量
          var regex = /<font[^>]*>(.*?)<\/font>/gi;//利用正则去掉<font></font>标签
          for (let [key, value] of Object.entries(data)) {
            console.log(`Key: ${key}, Value: ${value}`);
            let newObj = { key: key, value: value }; // 直接使用变量作为键，value 不需要数组
            console.log(newObj, "newObj");
            // 如果 value 是字符串且需要处理换行符，可以在这里进行
            let valueHtml =
              typeof value === "string" ? value.replace(/\n/g, "<br>") : value;
            // 不再需要 newObj.key 和 newObj.value，因为我们已经有了 key 和 valueHtml
            console.log(valueHtml, "valueHtml");
            title += `<p><strong>${key}</strong></p>`; // 不需要替换 \n 为 <br>
            html += `${valueHtml}`; // 使用处理过的 valueHtml
            console.log("输出html：", html);
          }
          //处理为字符串数组
          const templateLines = html.split(/\r?\n/);
          console.log(templateLines, "templateLines");
          //调用方法将数组中诶一个字符串处理为包含行号和内容的对象
          var objects = this.parseLines(templateLines);
          console.log(objects, "再次拆成对象");
          objects.forEach((obj) => {
            // 判断条件：<font color="...">标签
            if (this.findFontColor(obj.content)[0] == true) {
              // 添加colorFlag属性并设置其值为'red'
              // obj.colorFlag = this.findFontColor(obj.content)[0];-----好像没用到，哈哈哈哈先注释
              obj.color = this.findFontColor(obj.content)[1];
              obj.content = obj.content.replace(regex, (match, p1) => p1);
              obj.fontWeight = "bold";
            }
            // 如果你想要对于不满足条件的对象也添加一个colorFlag，但值为默认的'#303133'
            else if (this.findFontColor(obj.content)[0] == false) {
              // obj.colorFlag = this.findFontColor(obj.content)[0];----------好像没用到，哈哈哈哈先注释
              obj.color = this.findFontColor(obj.content)[1];
              obj.content = obj.content.replace(regex, (match, p1) => p1);
              obj.fontWeight = "normal";
            }
          });
          // return formattedText;
          this.detailData = {
            payloadTitle: title,
            payload: objects,
            solution: res.data.solution,
            summary: res.data.summary,
          };
          console.log(this.detailData.payload, "再次拆成对象payload");
          return;
        }
        let payloads = aa.replace(/\\n/g, "</br>");
        this.detailData = {
          payload: payloads,
          solution: res.data.solution,
          summary: res.data.summary,
        };
        console.log(payloads, "payloads");
      });
    },
    parseLines(lines) {
      return lines.map((line) => {
        // 使用正则表达式匹配行号和内容
        const lineNumberMatch = line.match(/^\s*(\d+):\s*(.*)/);
        console.log(lineNumberMatch.length, "lineNumberMatch s");
        // 如果匹配到行号，则提取行号和内容
        if (lineNumberMatch) {
          const lineNumber = parseInt(lineNumberMatch[1], 10) + ":";
          const content = line.substring(lineNumberMatch.length + 2); // 这里已经包含了冒号后的所有内容，包括空格
          console.log(line.substring(lineNumberMatch.length + 2), "content");
          return { lineNumber, content };
        }

        // 如果没有匹配到行号（比如是一个空行），则假设行号为null
        return { lineNumber: null, content: line }; //
      });
    },
    findFontColor(str) {
      // 正则表达式来匹配<font color="...">标签，并捕获color属性值
      const regex = /<font\s+[^>]*?color\s*=\s*(["'])([^"'>]*?)\1[^>]*>/i;
      let match = regex.exec(str);

      // 如果没有找到匹配项，返回false和空字符串
      if (!match) {
        return [false, "#303133"];
      }

      // 第一个捕获组是属性值周围的引号，第二个捕获组是属性值
      let color = match[2];

      // 返回true和color属性值
      return [true, color];
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogBehavior = false;
      this.dialogBehaviors = false;
      this.dialogFour = false; //四大组件
      this.dialogFour2 = false; //四大组件2
      this.dialogFour3 = false; //四大组件3
      this.dialogFour4 = false; //四大组件4
    },
    Ring(colorS) {
      //环形饼图
      var chartDom = document.getElementById("Ding");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "item",
        },
        color: colorS,
        // legend: {
        //   top: "5%",
        //   left: "center"
        // },
        // 设置环形中间的数据
        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
              normal: {
                // normal 是图形在默认状态下的样式
                show: true,
                position: "center",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                formatter: "{c}分", // {b}:数据名； {c}：数据值； {d}：百分比，可以自定义显示内容，
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.score,
          },
        ],
      };
      option && myChart.setOption(option);
    },
    pieChart() {
      // let labels = ['低危漏洞','高危漏洞','中危漏洞','安全']
      // let value =[]
      //饼图
      var chartDom = document.getElementById("main");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        // title: {
        //   text: "Referer of a Website",
        //   subtext: "Fake Data",
        //   left: "center"
        // },
        tooltip: {
          trigger: "item",
        },
        // legend: {
        //   orient: "vertical",
        //   left: "left"
        // },
        series: [
          {
            label: {
              normal: {
                formatter: "{b} : ({d}%)",
              },
            },
            // name: "Access From",
            type: "pie",
            // itemStyle:{
            //   normal:{
            //     label:{
            //       position:'inside',
            //       formatter:'{b} : {c} ({d}%)'
            //     }
            //   }
            // },
            radius: "50%",
            data: [
              {
                value: this.picChart.high,
                name: this.$t("AppManage.H5Scan.H5appShoptest.Highriskvu"),
                itemStyle: { color: "#fc2f3d" },
              },
              {
                value: this.picChart.medium,
                name: this.$t("AppManage.H5Scan.H5appShoptest.Mediumvu"),
                itemStyle: { color: "#ff8d00" },
              },
              {
                value: this.picChart.low,
                name: this.$t("AppManage.H5Scan.H5appShoptest.Lowriskvu"),
                itemStyle: { color: "#00c7ce" },
              },
              // {
              //   value: this.securityCount,
              //   name: '安全',
              //   itemStyle: { color: '#008000' }
              // }
            ],
            // data: this.mydata,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
.text {
  font-size: 14px;
  margin-left: 40px;
}
.box-card {
  position: relative;
}
// ::v-deep .el-card__body {
//     padding: 0px !important;
// }
.itemSpan {
  span {
    display: inline-block;
    width: 250px;
  }
}
.text-permi {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-card__body {
  overflow: auto;
}
.line-with-spaces {
  white-space: pre-wrap; /* 保留空格和换行符 */
}
</style>
